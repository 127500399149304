import React, { useEffect } from 'react';

// material-ui
import {
    Button,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Tooltip,
    IconButton,
    Chip
    // Tooltip,
    // Autocomplete,
    // IconButton
} from '@mui/material';
import TableHeader from 'ui-component/TableHead';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { fetchAllTransfers } from 'utils/api';
import Loader from 'ui-component/Loader';

import { useDispatch } from 'react-redux';
import { Delete, RefreshOutlined, RemoveRedEye, ViewAgenda, ViewColumn, ViewHeadline } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import ConfirmationModal from 'ui-component/Modal';

// table header options
const headCells = [
    {
        id: 'createdAt',
        numeric: true,
        label: 'Date',
        align: 'center'
    },
    {
        id: 'beneficiary',
        numeric: false,
        label: 'Beneficiary Details'
    },

    {
        id: 'debit_account_id',
        numeric: false,
        label: 'Debit Account Id',
        align: 'left'
    },
    {
        id: 'merchant_reference_id',
        numeric: false,
        label: 'Merchant Ref ID',
        align: 'left'
    },
    {
        id: 'type',
        numeric: false,
        label: 'Type',
        align: 'left'
    },
    {
        id: 'payment_mode',
        numeric: true,
        label: 'Mode',
        align: 'left'
    },
    {
        id: 'amount',
        numeric: false,
        label: 'Amount',
        align: 'left'
    },
    {
        id: 'status',
        numeric: false,
        label: 'Status',
        align: 'left'
    }
];

const Transfers = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [rows, setRows] = React.useState([]);
    const [allData, setAllData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [startAfter, setStartAfter] = React.useState();

    const [deactive, setDeactive] = React.useState(false);
    const [showMore, setShowMore] = React.useState(false);

    const fetchDataFromAPI = async (startAfter) => {
        setLoading(true);
        try {
            const result = await fetchAllTransfers(startAfter);
            setLoading(false);
            setShowMore(result.has_more);
            setAllData([...allData, ...result?.data]);
            setRows([...rows, ...result?.data]);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    React.useEffect(() => {
        fetchDataFromAPI(startAfter);
    }, [startAfter]);

    const handleCloseDialog = () => {
        setOpen(false);
        setDeactive(false);
        fetchDataFromAPI();
    };

    const handleChangePage = (event, newPage) => {
        setStartAfter(rows?.slice(-1)?.[0]?.id);
    };

    const handleDeactivate = () => {
        axiosServices
            .post('', {
                apiUrl: `https://api.zwitch.io/v1/accounts/${deactive.id}/deactivate`,
                body: {},
                method: 'POST'
            })
            .then((res) => {
                setLoading(false);
                handleCloseDialog();
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Deactivated Successfully',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
            })
            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.error?.message || 'Error while deactivating account',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>Transfers</div>
                        <Tooltip title="Refresh" sx={{ ml: 2 }}>
                            <RefreshOutlined style={{ fontSize: 16, cursor: 'pointer' }} onClick={() => window.location.reload()} />
                        </Tooltip>
                    </div>
                    {/* <div>
                        <Button disabled={loading} variant="contained" onClick={() => setOpen(true)}>
                            Add Account
                        </Button>
                    </div> */}
                </div>
            }
            content={false}
        >
            {loading && <Loader />}

            {/* <AddAccount open={open} handleCloseDialog={handleCloseDialog} /> */}
            <TableContainer sx={{ height: '80vh' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows?.map((row, index) => {
                            return (
                                <TableRow key={index} hover>
                                    <TableCell align="center">
                                        <div style={{ minWidth: 130 }}>
                                            {row.created_at ? new Date(row.created_at * 1000).toLocaleString() : '-'}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ minWidth: 220 }}>
                                            <div>ID: {row.beneficiary_id}</div>
                                            <div>Name: {row.beneficiary_name}</div>
                                            <div>IFSC: {row.beneficiary_ifsc}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell>{row.debit_account_id}</TableCell>
                                    <TableCell>
                                        <div style={{ minWidth: 120 }}>{row.merchant_reference_id}</div>
                                    </TableCell>

                                    <TableCell>
                                        <div style={{ minWidth: 100 }}>{row.type}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ minWidth: 100 }}>{row.payment_mode}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ minWidth: 100 }}>{row.amount}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ minWidth: 100 }}>
                                            <Chip
                                                size="small"
                                                label={row.status}
                                                color={
                                                    row.status === 'success' ? 'success' : row.status === 'pending' ? 'warning' : 'error'
                                                }
                                            />
                                        </div>
                                    </TableCell>
                                    {/* <TableCell align="center">
                                        <div style={{ display: 'flex', minWidth: 120 }}>
                                            <Tooltip placement="top" title="View Payments">
                                                <IconButton color="primary" aria-label="edit" size="large">
                                                    <RemoveRedEye
                                                        onClick={() => navigate(`/payments/${row.id}`)}
                                                        sx={{ fontSize: '1.1rem' }}
                                                    />
                                                </IconButton>
                                            </Tooltip>

                                            {row.used_as === 'primary' ? (
                                                <Tooltip placement="top" title="View Beneficiaries">
                                                    <IconButton color="primary" aria-label="edit" size="large">
                                                        <ViewHeadline
                                                            onClick={() => navigate(`/beneficiaries/${row.id}`)}
                                                            sx={{ fontSize: '1.1rem' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip style={{visibility: 'hidden'}} placement="top" title="View Beneficiaries">
                                                    <IconButton color="primary" aria-label="edit" size="large">
                                                        <ViewHeadline
                                                            // onClick={() => navigate(`/beneficiaries/${row.id}`)}
                                                            sx={{ fontSize: '1.1rem' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            )}

                                            {row.status === 'active' ? (
                                                <Tooltip placement="top" title="Deactivate">
                                                    <IconButton color="primary" aria-label="edit" size="large">
                                                        <Delete
                                                            onClick={() => setDeactive(row)}
                                                            sx={{ fontSize: '1.1rem', color: 'red' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </TableCell> */}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <ConfirmationModal
                open={deactive}
                title="Deactivate Account"
                subTitle="Are you sure, you want to Deactivate selected Account"
                handleClose={() => setDeactive(false)}
                handleSubmit={handleDeactivate}
                loading={loading}
            />

            {showMore && (
                <div
                    onClick={handleChangePage}
                    style={{ cursor: 'pointer', margin: 20, textAlign: 'right', fontWeight: 'bold', color: '#2096f3' }}
                >
                    Show More
                </div>
            )}
            {/* table pagination */}
            {/* <TablePagination
                rowsPerPageOptions={[100]}
                component="div"
                count={allData?.length + 1}
                rowsPerPage={100}
                page={0}
                onPageChange={handleChangePage}
            /> */}
        </MainCard>
    );
};

export default Transfers;
