import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, TextField, Autocomplete, MenuItem } from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const validationSchema = yup.object({
    type: yup.string().required('Required Field').nullable(),
    name_of_account_holder: yup.string().required('Required Field').nullable(),
    email: yup.string().required('Required Field').nullable(),
    phone: yup.string().required('Required Field').nullable()
});

// ==============================|| Merchant ADD DIALOG ||============================== //

const AddAccount = ({ id, open, handleCloseDialog }) => {
    const dispatch = useDispatch();

    // const [tobeSettledPayInAmount, setTobeSettledPayInAmount] = useState();

    const [details, setDetails] = useState({
        // conversionType: 'INR',
        // valueType: 'PAYIN'
        type: 'vpa'
    });

    const [type, setType] = useState('vpa');

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            let payload = {
                type: data.type,
                name_of_account_holder: data?.name_of_account_holder,
                phone: data.phone?.toString(),
                email: data.email
            };

            if (data.type == 'vpa') {
                if (data.vpa) {
                    payload = { ...payload, vpa: data.vpa };
                } else {
                    return dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Please add vpa handle',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                }
            } else {
                if (!data.bank_ifsc_code || !data.bank_account_number) {
                    return dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Please add account details',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                }

                payload = { ...payload, bank_account_number: data?.bank_account_number?.toString(), bank_ifsc_code: data?.bank_ifsc_code };
            }

            setLoading(true);

            axiosServices
                .post('', {
                    apiUrl: `https://api.zwitch.io/v1/accounts/${id}/beneficiaries`,
                    body: payload,
                    method: 'POST'
                })
                .then((res) => {
                    setLoading(false);
                    resetForm();
                    handleCloseDialog();
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Added Successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.error?.message || 'Error while adding account',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                    dispatch(closeSnackbar);
                });
        }
    });

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            // sx={{
            //     '&>div:nth-of-type(3)': {
            //         justifyContent: 'flex-end',
            //         '&>div': {
            //             m: 0,
            //             borderRadius: '0px',
            //             maxWidth: 450,
            //             maxHeight: '100%'
            //         }
            //     }
            // }}
        >
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>{open?.id ? 'Edit Account' : 'Add Beneficiary'}</DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}

                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="name_of_account_holder"
                                    name="name_of_account_holder"
                                    label="Name"
                                    value={formik.values.name_of_account_holder || ''}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.name_of_account_holder)}
                                    helperText={formik.errors.name_of_account_holder}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="phone"
                                    name="phone"
                                    label="Mobile Number"
                                    type="number"
                                    value={formik.values.phone || ''}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.phone)}
                                    helperText={formik.errors.phone}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    value={formik.values.email || ''}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.email)}
                                    helperText={formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    select
                                    id="type"
                                    name="type"
                                    label="Type of Beneficiary"
                                    value={formik.values.type}
                                    onChange={(e) => {
                                        formik.setFieldValue('type', e.target.value);
                                        setType(e.target.value);
                                    }}
                                    error={Boolean(formik.errors?.type)}
                                    helperText={formik.errors?.type}
                                >
                                    <MenuItem value="account_number">Account Number</MenuItem>
                                    <MenuItem value="vpa">VPA</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <div style={{ fontWeight: 500 }}>Details</div>
                            </Grid>

                            {type === 'vpa' ? (
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        id="vpa"
                                        name="vpa"
                                        label="VPA handle"
                                        value={formik.values.vpa || ''}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.errors.vpa)}
                                        helperText={formik.errors.vpa}
                                    />
                                </Grid>
                            ) : (
                                <>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            id="bank_account_number"
                                            name="bank_account_number"
                                            label="Account Number"
                                            type="number"
                                            value={formik.values.bank_account_number || ''}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.errors.bank_account_number)}
                                            helperText={formik.errors.bank_account_number}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            id="bank_ifsc_code"
                                            name="bank_ifsc_code"
                                            label="IFSC"
                                            value={formik.values.bank_ifsc_code || ''}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.errors.bank_ifsc_code)}
                                            helperText={formik.errors.bank_ifsc_code}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                setDetails({});
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

AddAccount.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default AddAccount;
