import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Chip
} from '@mui/material';

import { gridSpacing } from 'store/constant';
import { useDispatch } from 'react-redux';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';
import TableHeader from 'ui-component/TableHead';
import { fetchBeneficiariesTrasnfers } from 'utils/api';
import { IconCross } from '@tabler/icons';
import Close from '@mui/icons-material/Close';

// table header options
const headCells = [
    {
        id: 'createdAt',
        numeric: true,
        label: 'Date',
        align: 'center'
    },
    {
        id: 'beneficiary',
        numeric: false,
        label: 'Beneficiary Details'
    },

    {
        id: 'debit_account_id',
        numeric: false,
        label: 'Debit Account Id',
        align: 'left'
    },
    {
        id: 'merchant_reference_id',
        numeric: false,
        label: 'Merchant Ref ID',
        align: 'left'
    },
    {
        id: 'type',
        numeric: false,
        label: 'Type',
        align: 'left'
    },
    {
        id: 'payment_mode',
        numeric: true,
        label: 'Mode',
        align: 'left'
    },
    {
        id: 'amount',
        numeric: false,
        label: 'Amount',
        align: 'left'
    },
    {
        id: 'status',
        numeric: false,
        label: 'Status',
        align: 'left'
    }
];

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

// ==============================|| Merchant ADD DIALOG ||============================== //

const AllTransfers = ({ id, open, handleCloseDialog }) => {
    const dispatch = useDispatch();

    const [details, setDetails] = useState([]);

    const [loading, setLoading] = useState(false);

    const fetchDataFromAPI = async () => {
        if (id) {
            setLoading(true);
            const result = await fetchBeneficiariesTrasnfers(id);
            setLoading(false);
            setDetails(result?.data || []);
        }
    };

    useEffect(() => {
        fetchDataFromAPI();
    }, [id]);

    return (
        <Dialog maxWidth="lg" fullWidth open={open} TransitionComponent={Transition} keepMounted>
            {open && (
                <div>
                    <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>All Transfers</div>
                        <div onClick={handleCloseDialog}>
                            <Close />
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}
                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            <TableContainer sx={{ height: '80vh' }}>
                                <Table sx={{ minWidth: 750, mt: 3 }} aria-labelledby="tableTitle">
                                    <TableHeader columns={headCells} />
                                    <TableBody>
                                        {details?.map((row, index) => {
                                            return (
                                                <TableRow key={index} hover>
                                                    <TableCell align="center">
                                                        <div style={{ minWidth: 130 }}>
                                                            {row.created_at ? new Date(row.created_at * 1000).toLocaleString() : '-'}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div style={{ minWidth: 220 }}>
                                                            <div>ID: {row.beneficiary_id}</div>
                                                            <div>Name: {row.beneficiary_name}</div>
                                                            <div>IFSC: {row.beneficiary_ifsc}</div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>{row.debit_account_id}</TableCell>
                                                    <TableCell>
                                                        <div style={{ minWidth: 120 }}>{row.merchant_reference_id}</div>
                                                    </TableCell>

                                                    <TableCell>
                                                        <div style={{ minWidth: 100 }}>{row.type}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div style={{ minWidth: 100 }}>{row.payment_mode}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div style={{ minWidth: 100 }}>{row.amount}</div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div style={{ minWidth: 100 }}>
                                                            <Chip
                                                                size="small"
                                                                label={row.status}
                                                                color={
                                                                    row.status === 'success' ? 'success' : row.status === 'pending' ? 'warning' : 'error'
                                                                }
                                                            />
                                                        </div>
                                                    </TableCell>
                                                    {/* <TableCell align="center">
                                        <div style={{ display: 'flex', minWidth: 120 }}>
                                            <Tooltip placement="top" title="View Payments">
                                                <IconButton color="primary" aria-label="edit" size="large">
                                                    <RemoveRedEye
                                                        onClick={() => navigate(`/payments/${row.id}`)}
                                                        sx={{ fontSize: '1.1rem' }}
                                                    />
                                                </IconButton>
                                            </Tooltip>

                                            {row.used_as === 'primary' ? (
                                                <Tooltip placement="top" title="View Beneficiaries">
                                                    <IconButton color="primary" aria-label="edit" size="large">
                                                        <ViewHeadline
                                                            onClick={() => navigate(`/beneficiaries/${row.id}`)}
                                                            sx={{ fontSize: '1.1rem' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip style={{visibility: 'hidden'}} placement="top" title="View Beneficiaries">
                                                    <IconButton color="primary" aria-label="edit" size="large">
                                                        <ViewHeadline
                                                            // onClick={() => navigate(`/beneficiaries/${row.id}`)}
                                                            sx={{ fontSize: '1.1rem' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            )}

                                            {row.status === 'active' ? (
                                                <Tooltip placement="top" title="Deactivate">
                                                    <IconButton color="primary" aria-label="edit" size="large">
                                                        <Delete
                                                            onClick={() => setDeactive(row)}
                                                            sx={{ fontSize: '1.1rem', color: 'red' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </TableCell> */}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </DialogContent>
                </div>
            )}
        </Dialog>
    );
};

AllTransfers.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default AllTransfers;
