import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, TextField, Autocomplete, MenuItem } from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const validationSchema = yup.object({
    amount: yup.string().required('Required Field').nullable(),
    merchant_reference_id: yup.string().required('Required Field').min(7).max(40)
});

// ==============================|| Merchant ADD DIALOG ||============================== //

const Transfer = ({ id, open, handleCloseDialog }) => {
    const dispatch = useDispatch();

    const [details, setDetails] = useState({});

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            let payload = {
                type: open?.type,
                beneficiary_id: open?.id,
                debit_account_id: id,
                currency_code: 'inr',
                amount: data?.amount,
                merchant_reference_id: data?.merchant_reference_id
            };

            if (open.type == 'account_number') {
                if (data.payment_mode) {
                    payload = { ...payload, payment_mode: data.payment_mode };
                } else {
                    return dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Please select payment mode',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: false
                        })
                    );
                }
            }

            setLoading(true);

            axiosServices
                .post('', {
                    apiUrl: `https://api.zwitch.io/v1/transfers`,
                    body: payload,
                    method: 'POST'
                })
                .then((res) => {
                    setLoading(false);
                    resetForm();
                    handleCloseDialog();
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Success',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.error?.message || 'Error',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                    dispatch(closeSnackbar);
                });
        }
    });

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted>
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>{'Transfer'}</DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}

                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            {open?.type === 'account_number' ? (
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        select
                                        id="payment_mode"
                                        name="payment_mode"
                                        label="Payment Mode"
                                        value={formik.values.payment_mode}
                                        onChange={(e) => {
                                            formik.setFieldValue('payment_mode', e.target.value);
                                        }}
                                        error={Boolean(formik.errors?.payment_mode)}
                                        helperText={formik.errors?.payment_mode}
                                    >
                                        <MenuItem value="neft">NEFT</MenuItem>
                                        <MenuItem value="imps">IMPS</MenuItem>
                                        <MenuItem value="rtgs">RTGS</MenuItem>
                                    </TextField>
                                </Grid>
                            ) : (
                                <></>
                            )}

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="amount"
                                    name="amount"
                                    label="Amount"
                                    type="number"
                                    value={formik.values.amount || ''}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.amount)}
                                    helperText={formik.errors.amount}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="merchant_reference_id"
                                    name="merchant_reference_id"
                                    label="Merchant Ref ID"
                                    value={formik.values.merchant_reference_id || ''}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.merchant_reference_id)}
                                    helperText={formik.errors.merchant_reference_id}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                setDetails({});
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

Transfer.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default Transfer;
