import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, TextField, Autocomplete, MenuItem } from '@mui/material';

import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import Loader from 'ui-component/Loader';
import useAuth from 'hooks/useAuth';
import { BIZ_CATEGORY, BIZ_TYPES, STATES } from 'constant';

// animation
const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const validationSchema = yup.object({
    name: yup.string().required('Required Field').nullable(),
    email: yup.string().required('Required Field').nullable(),
    mobile_number: yup.string().required('Required Field').nullable(),
    state_code: yup.string().required('Required Field').nullable(),
    postal_code: yup.string().required('Required Field').nullable(),
    city: yup.string().required('Required Field').nullable(),
    pan: yup.string().required('Required Field').nullable(),
    business_category: yup.string().required('Required Field').nullable(),
    business_type: yup.string().required('Required Field').nullable(),
    contact_person: yup.string().required('Required Field').nullable()
});

// ==============================|| Merchant ADD DIALOG ||============================== //

const AddAccount = ({ open, handleCloseDialog }) => {
    const dispatch = useDispatch();
    const { user } = useAuth();

    // const [tobeSettledPayInAmount, setTobeSettledPayInAmount] = useState();

    const [details, setDetails] = useState({
        // conversionType: 'INR',
        // valueType: 'PAYIN'
        create_vpa: 'yes'
    });

    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        enableReinitialize: true,
        initialValues: details,
        validationSchema,
        onSubmit: (data, { resetForm }) => {
            const payload = {
                type: 'virtual',
                used_as: 'collection_tool',
                create_vpa: data?.create_vpa === 'yes' ? true : false,
                name: data?.name,
                mobile_number: data.mobile_number?.toString(),
                email: data.email,
                kyc: {
                    city: data.city,
                    postal_code: data.postal_code,
                    state_code: data.state_code,
                    pan: data.pan,
                    business_type: data.business_type,
                    business_category: data.business_category,
                    contact_person: data.contact_person
                }
            };
            setLoading(true);

            axiosServices
                .post('', {
                    apiUrl: 'https://api.zwitch.io/v1/accounts',
                    body: payload,
                    method: 'POST'
                })
                .then((res) => {
                    setLoading(false);
                    resetForm();
                    handleCloseDialog();
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Added Successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.error?.message || 'Error while adding account',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                    dispatch(closeSnackbar);
                });
        }
    });

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            // sx={{
            //     '&>div:nth-of-type(3)': {
            //         justifyContent: 'flex-end',
            //         '&>div': {
            //             m: 0,
            //             borderRadius: '0px',
            //             maxWidth: 450,
            //             maxHeight: '100%'
            //         }
            //     }
            // }}
        >
            {open && (
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>{open?.id ? 'Edit Account' : 'Add Account'}</DialogTitle>
                    <DialogContent>
                        {loading && <Loader />}

                        <Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="name"
                                    name="name"
                                    label="Name"
                                    value={formik.values.name || ''}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.name)}
                                    helperText={formik.errors.name}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="mobile_number"
                                    name="mobile_number"
                                    label="Mobile Number"
                                    type="number"
                                    value={formik.values.mobile_number || ''}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.mobile_number)}
                                    helperText={formik.errors.mobile_number}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    value={formik.values.email || ''}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.email)}
                                    helperText={formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    select
                                    id="create_vpa"
                                    name="create_vpa"
                                    label="Create VPA"
                                    value={formik.values.create_vpa}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors?.create_vpa)}
                                    helperText={formik.errors?.create_vpa}
                                >
                                    <MenuItem value="yes">YES</MenuItem>
                                    <MenuItem value="no">NO</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <div style={{ fontWeight: 500 }}>KYC Details</div>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    select
                                    id="state_code"
                                    name="state_code"
                                    label="State Code"
                                    value={formik.values?.state_code}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors?.state_code)}
                                    helperText={formik.errors?.state_code}
                                >
                                    {STATES.map((c) => (
                                        <MenuItem value={c.ID}>{c.Name}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="city"
                                    name="city"
                                    label="City"
                                    value={formik.values.city || ''}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.city)}
                                    helperText={formik.errors.city}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="postal_code"
                                    name="postal_code"
                                    label="Postal Code"
                                    type="number"
                                    value={formik.values.postal_code || ''}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.postal_code)}
                                    helperText={formik.errors.postal_code}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="pan"
                                    name="pan"
                                    label="PAN"
                                    value={formik.values.pan || ''}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.pan)}
                                    helperText={formik.errors.pan}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    select
                                    id="business_type"
                                    name="business_type"
                                    label="Business Type"
                                    value={formik.values?.business_type}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors?.business_type)}
                                    helperText={formik.errors?.business_type}
                                >
                                    {BIZ_TYPES.map((c) => (
                                        <MenuItem value={c.ID}>{c.Name}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    select
                                    id="business_category"
                                    name="business_category"
                                    label="Business Category"
                                    value={formik.values?.business_category}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors?.business_category)}
                                    helperText={formik.errors?.business_category}
                                >
                                    {BIZ_CATEGORY.map((c) => (
                                        <MenuItem value={c.ID}>{c.Name}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="contact_person"
                                    name="contact_person"
                                    label="Contact Person"
                                    value={formik.values.contact_person || ''}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.errors.contact_person)}
                                    helperText={formik.errors.contact_person}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <AnimateButton>
                            <Button disabled={loading} type="submit" variant="contained">
                                Save
                            </Button>
                        </AnimateButton>
                        <Button
                            variant="text"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                                formik.resetForm();
                                setDetails({});
                                handleCloseDialog();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    );
};

AddAccount.propTypes = {
    open: PropTypes.bool,
    handleCloseDialog: PropTypes.func
};

export default AddAccount;
