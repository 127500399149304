import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

// material-ui
import {
    Button,
    Chip,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Tooltip
} from '@mui/material';

import { gridSpacing } from 'store/constant';
import Loader from 'ui-component/Loader';
import { fetchAccountBeneficiaries } from 'utils/api';
import MainCard from 'ui-component/cards/MainCard';
import TableHeader from 'ui-component/TableHead';
import { RefreshOutlined, Delete, TransferWithinAStation } from '@mui/icons-material';
import AddAccount from './Add';
import ConfirmationModal from 'ui-component/Modal';
import axiosServices from 'utils/axios';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'react-redux';
import { IconTransferIn } from '@tabler/icons';
import Transfer from './Transfer';
import AllTransfers from './AllTransfers';

// table header options
const headCells = [
    {
        id: 'createdAt',
        numeric: true,
        label: 'Date',
        align: 'center'
    },
    {
        id: 'id',
        numeric: false,
        label: 'Name/ID'
    },
    {
        id: 'Contact',
        numeric: false,
        label: 'Contact',
        align: 'left'
    },
    {
        id: 'vpa',
        numeric: true,
        label: 'VPA',
        align: 'left'
    },
    {
        id: 'account',
        numeric: true,
        label: 'Account',
        align: 'left'
    },
    {
        id: 'type',
        numeric: false,
        label: 'Type',
        align: 'left'
    },
    {
        id: 'status',
        numeric: false,
        label: 'Status',
        align: 'left'
    },
    {
        id: 'Action',
        numeric: false,
        label: 'Action',
        align: 'center'
    }
];

const Beneficiaries = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const [deactive, setDeactive] = useState(false);
    const [transfer, setTransfer] = useState(false);
    const [openTransfer, setOpenTransfer] = useState(false);

    const [details, setDetails] = useState();
    const [rows, setRows] = useState([]);

    const fetchDataFromAPI = async () => {
        if (id) {
            setLoading(true);
            const result = await fetchAccountBeneficiaries(id);
            setLoading(false);
            setDetails(result?.data || []);
            setRows(result?.data || []);
        }
    };

    useEffect(() => {
        fetchDataFromAPI();
    }, [id]);

    const handleCloseDialog = () => {
        setOpen(false);
        setTransfer(false);
        fetchDataFromAPI();
    };

    const handleDeactivate = () => {
        axiosServices
            .post('', {
                apiUrl: `https://api.zwitch.io/v1/accounts/beneficiaries/${deactive.id}`,
                body: {},
                method: 'DELETE'
            })
            .then((res) => {
                setLoading(false);
                handleCloseDialog();
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Deleted Successfully',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
            })
            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.error?.message || 'Error while deleting account',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>Beneficiaries</div>
                        <Tooltip title="Refresh" sx={{ ml: 2 }}>
                            <RefreshOutlined style={{ fontSize: 16, cursor: 'pointer' }} onClick={() => window.location.reload()} />
                        </Tooltip>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button disabled={loading} variant="outlined" onClick={() => setOpenTransfer(true)}>
                            View All Transfers
                        </Button>
                        <Button disabled={loading} variant="contained" style={{ marginLeft: 15 }} onClick={() => setOpen(true)}>
                            Add Beneficiary
                        </Button>
                    </div>
                </div>
            }
        >
            <Grid container justifyContent="center" spacing={gridSpacing}></Grid>
            {loading && <Loader />}
            <TableContainer sx={{ height: '80vh' }}>
                <Table sx={{ minWidth: 750, mt: 3 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows?.map((row, index) => {
                            return (
                                <TableRow key={index} hover>
                                    <TableCell align="center">
                                        <div style={{ width: 130 }}>
                                            {row.created_at ? new Date(row.created_at * 1000).toLocaleString() : '-'}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 250 }}>
                                            <div>ID: {row.id}</div>
                                            <div>Name: {row.name_of_account_holder}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ minWidth: 200 }}>
                                            <div>Phone: {row.phone}</div>
                                            <div>Email: {row.email}</div>
                                        </div>
                                    </TableCell>
                                    <div style={{ minWidth: 120 }}>{row.vpa}</div>
                                    <TableCell>
                                        <div style={{ minWidth: 220 }}>
                                            <div>Acc No: {row.bank_account_number}</div>
                                            <div>IFSC: {row.bank_ifsc_code}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ minWidth: 120 }}>{row.type}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ minWidth: 80 }}>
                                            <Chip size="small" label={row.status} color={row.status === 'active' ? 'success' : 'error'} />
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <div style={{ display: 'flex' }}>
                                            <Tooltip placement="top" title="Transfer">
                                                <IconButton color="primary" aria-label="edit" size="large">
                                                    <IconTransferIn onClick={() => setTransfer(row)} sx={{ fontSize: '1.1rem' }} />
                                                </IconButton>
                                            </Tooltip>

                                            {row.status === 'active' ? (
                                                <Tooltip placement="top" title="Delete">
                                                    <IconButton color="primary" aria-label="edit" size="large">
                                                        <Delete
                                                            onClick={() => setDeactive(row)}
                                                            sx={{ fontSize: '1.1rem', color: 'red' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </TableCell>
                                    {/* <TableCell align="center">
                                        <Tooltip placement="top" title="View Beneficiaries">
                                            <IconButton color="primary" aria-label="edit" size="large">
                                                <RemoveRedEye sx={{ fontSize: '1.1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                        </TableCell> */}

                                    {/* <Tooltip placement="top" title="Delete">
                                <IconButton color="primary" aria-label="edit" size="large">
                                    <Delete onClick={() => setDeleteTxn(row)} sx={{ fontSize: '1.1rem', color: 'red' }} />
                                </IconButton>
                            </Tooltip> */}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <AddAccount id={id} open={open} handleCloseDialog={handleCloseDialog} />
            <Transfer id={id} open={transfer} handleCloseDialog={handleCloseDialog} />
            <AllTransfers id={id} open={openTransfer} handleCloseDialog={() => setOpenTransfer(false)} />

            <ConfirmationModal
                open={deactive}
                title="Delete Beneficiary"
                subTitle="Are you sure, you want to Delete selected Beneficiary"
                handleClose={() => setDeactive(false)}
                handleSubmit={handleDeactivate}
                loading={loading}
            />

            {/* <TablePagination
                rowsPerPageOptions={[100, 200]}
                component="div"
                count={rows?.length}
                rowsPerPage={100}
                page={0}
                // onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </MainCard>
    );
};

export default Beneficiaries;
