import axios from 'utils/axios';

export const fetchPrimaryAccounts = async () => {
    const response = await axios.post('', {
        apiUrl: 'https://api.zwitch.io/v1/accounts?results_per_page=100&type=virtual&used_as=primary',
        body: null,
        method: 'GET'
    });
    return response.data;
};

export const fetchAccounts = async () => {
    const response = await axios.post('', {
        apiUrl: 'https://api.zwitch.io/v1/accounts?results_per_page=100&type=virtual&used_as=collection_tool',
        body: null,
        method: 'GET'
    });
    return response.data;
};

export const fetchAccountPayments = async (id, start_after) => {
    const response = await axios.post('', {
        apiUrl: start_after
            ? 'https://api.zwitch.io/v1/accounts/' + id + '/payments?results_per_page=100&start_after=' + start_after
            : 'https://api.zwitch.io/v1/accounts/' + id + '/payments?results_per_page=100',
        body: null,
        method: 'GET'
    });
    return response.data;
};

export const fetchAccountSettlements = async (id, start_after) => {
    const response = await axios.post('', {
        apiUrl: start_after
            ? 'https://api.zwitch.io/v1/accounts/' + id + '/statements?results_per_page=100&start_after=' + start_after
            : 'https://api.zwitch.io/v1/accounts/' + id + '/statements?results_per_page=100',
        body: null,
        method: 'GET'
    });
    return response.data;
};

export const fetchAccountBeneficiaries = async (id) => {
    const response = await axios.post('', {
        apiUrl: 'https://api.zwitch.io/v1/accounts/' + id + '/beneficiaries?results_per_page=100',
        body: null,
        method: 'GET'
    });
    return response.data;
};

export const fetchAccountBalance = async (id) => {
    const response = await axios.post('', {
        apiUrl: 'https://api.zwitch.io/v1/accounts/' + id + '/balance',
        body: null,
        method: 'GET'
    });
    return response.data;
};

export const fetchAllTransfers = async (start_after) => {
    const response = await axios.post('', {
        apiUrl: start_after
            ? 'https://api.zwitch.io/v1/transfers?results_per_page=100&start_after=' + start_after
            : 'https://api.zwitch.io/v1/transfers?results_per_page=100',
        body: null,
        method: 'GET'
    });
    return response.data;
};

export const fetchBeneficiariesTrasnfers = async (id) => {
    const response = await axios.post('', {
        apiUrl: 'https://api.zwitch.io/v1/transfers?beneficiary_id=' + id,
        body: null,
        method: 'GET'
    });
    return response.data;
};
