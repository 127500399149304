import { MerchantDashboard } from './dashboard';

// ==============================|| MENU ITEMS ||============================== //

const DefaultMenuItems = {
    items: [MerchantDashboard]
};

export const MerchantMenuItems = {
    items: [MerchantDashboard]
};

export default DefaultMenuItems;
