import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

// material-ui
import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Tooltip } from '@mui/material';

import { gridSpacing } from 'store/constant';
import Loader from 'ui-component/Loader';
import { fetchAccountSettlements } from 'utils/api';
import MainCard from 'ui-component/cards/MainCard';
import TableHeader from 'ui-component/TableHead';

// table header options
const headCells = [
    {
        id: 'transacted_at',
        numeric: true,
        label: 'Txn Date',
        align: 'center'
    },
    {
        id: 'ref',
        numeric: false,
        label: 'Bank Ref'
    },
    {
        id: 'balance',
        numeric: true,
        label: 'Balance',
        align: 'left'
    },
    {
        id: 'credit',
        numeric: false,
        label: 'Credit',
        align: 'left'
    },
    {
        id: 'debit',
        numeric: true,
        label: 'Debit',
        align: 'left'
    },
    {
        id: 'remark',
        numeric: false,
        label: 'remark',
        align: 'left'
    },
    {
        id: 'source',
        numeric: true,
        label: 'Source',
        align: 'left'
    }
];

const Settlements = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    const [details, setDetails] = useState([]);
    const [rows, setRows] = useState([]);

    const [startAfter, setStartAfter] = useState();
    const [showMore, setShowMore] = useState(false);

    const fetchDataFromAPI = async (startAfter) => {
        if (id) {
            setLoading(true);
            const result = await fetchAccountSettlements(id, startAfter);
            setLoading(false);
            setShowMore(result.has_more);
            setDetails([...details, ...result?.data]);
            setRows([...rows, ...result?.data]);
        }
    };

    useEffect(() => {
        fetchDataFromAPI(startAfter);
    }, [id, startAfter]);

    const handleChangePage = (event, newPage) => {
        setStartAfter(rows?.slice(-1)?.[0]?.id);
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>Settlements</div>
                        <div style={{ marginLeft: 5, fontSize: 14 }}>(Account ID: {id})</div>
                    </div>
                </div>
            }
        >
            <Grid container justifyContent="center" spacing={gridSpacing}></Grid>
            {loading && <Loader />}
            <TableContainer sx={{ height: '80vh' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows?.map((row, index) => {
                            return (
                                <TableRow key={index} hover>
                                    <TableCell align="center">
                                        {row.transacted_at ? new Date(row.created_at * 1000).toLocaleString() : '-'}
                                    </TableCell>
                                    <TableCell sx={{ minWidth: 120 }}>
                                        <div>{row.bank_reference_number}</div>
                                    </TableCell>
                                    <TableCell sx={{ minWidth: 80 }}>
                                        <div>{row.balance}</div>
                                    </TableCell>
                                    <TableCell sx={{ minWidth: 80 }}>{row.credit}</TableCell>

                                    <TableCell sx={{ minWidth: 80 }}>{row.debit}</TableCell>
                                    <TableCell>{row.remark}</TableCell>

                                    <div title={JSON.stringify(row.source_details, null, 4)}>
                                        <TableCell sx={{ minWidth: 100, color: 'blue' }}>{row.source}</TableCell>
                                    </div>

                                    {/* <TableCell align="center">
                                        <Tooltip placement="top" title="View Settlements">
                                            <IconButton color="primary" aria-label="edit" size="large">
                                                <RemoveRedEye sx={{ fontSize: '1.1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                        </TableCell> */}

                                    {/* <Tooltip placement="top" title="Delete">
                                <IconButton color="primary" aria-label="edit" size="large">
                                    <Delete onClick={() => setDeleteTxn(row)} sx={{ fontSize: '1.1rem', color: 'red' }} />
                                </IconButton>
                            </Tooltip> */}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {showMore && (
                <div
                    onClick={handleChangePage}
                    style={{ cursor: 'pointer', margin: 20, textAlign: 'right', fontWeight: 'bold', color: '#2096f3' }}
                >
                    Show More
                </div>
            )}
            {/* <TablePagination
                rowsPerPageOptions={[100, 200]}
                component="div"
                count={rows?.length}
                rowsPerPage={100}
                page={0}
                // onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
        </MainCard>
    );
};

export default Settlements;
