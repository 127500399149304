// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconActivity, IconDashboard, IconDeviceAnalytics, IconBadge } from '@tabler/icons';

const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconBadge,
    IconActivity
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const MerchantDashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="Accounts" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'primary-accounts',
            title: <FormattedMessage id="Primary Accounts" />,
            type: 'item',
            url: '/primary-accounts',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'default',
            title: <FormattedMessage id="VCT Accounts" />,
            type: 'item',
            url: '/accounts',
            icon: icons.IconActivity,
            breadcrumbs: false
        },
        {
            id: 'transfers',
            title: <FormattedMessage id="Transfers" />,
            type: 'item',
            url: '/transfers',
            icon: icons.IconBadge,
            breadcrumbs: false
        }
    ]
};
