import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Payments from 'views/others/Accounts/Payments';
import Beneficiaries from 'views/others/Beneficiaries';
import Transfers from 'views/others/Transfers';
import PrimaryAccounts from 'views/others/PrimaryAccounts';
import Settlements from 'views/others/PrimaryAccounts/Settlements';

// other pages
const Accounts = Loadable(lazy(() => import('views/others/Accounts')));

const childrenRoutes = [
    {
        path: 'accounts',
        element: <Accounts />
    },
    {
        path: 'primary-accounts',
        element: <PrimaryAccounts />
    },
    {
        path: 'transfers',
        element: <Transfers />
    },
    {
        path: 'payments/:id',
        element: <Payments />
    },
    {
        path: 'settlements/:id',
        element: <Settlements />
    },
    {
        path: 'beneficiaries/:id',
        element: <Beneficiaries />
    }
];

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: childrenRoutes
};

export default MainRoutes;
