const LAYOUT_CONST = {
    VERTICAL_LAYOUT: 'vertical',
    HORIZONTAL_LAYOUT: 'horizontal',
    DEFAULT_DRAWER: 'default',
    MINI_DRAWER: 'mini-drawer'
};

export default LAYOUT_CONST;

export const ROLE_SUPERADMIN = 'ROLE_SUPERADMIN';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_AGENT = 'ROLE_AGENT';
export const ROLE_MERCHANT = 'ROLE_MERCHANT';

export const AVAILABLE_METHODS = ['UPI', 'IMPS', 'UPI,IMPS'];

// Get the current date
const currentDate = new Date();

// Create options for formatting the date
const options = {
    timeZone: 'Asia/Kolkata', // 'Asia/Kolkata' is the time zone for IST
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
};

// Format the date in IST
const date = currentDate.toLocaleString('en-IN', options).split('/');

export const ISTDate = `${date[2]}-${date[1]}-${date[0]}`;

export const STATES = [
    {
        ID: 'AP',
        Name: 'Andhra Pradesh'
    },
    {
        ID: 'AR',
        Name: 'Arunachal Pradesh'
    },
    {
        ID: 'AS',
        Name: 'Assam'
    },
    {
        ID: 'BR',
        Name: 'Bihar'
    },
    {
        ID: 'CT',
        Name: 'Chhattisgarh'
    },
    {
        ID: 'GA',
        Name: 'Goa'
    },
    {
        ID: 'GJ',
        Name: 'Gujarat'
    },
    {
        ID: 'HR',
        Name: 'Haryana'
    },
    {
        ID: 'HP',
        Name: 'Himachal Pradesh'
    },
    {
        ID: 'JK',
        Name: 'Jammu and Kashmir'
    },
    {
        ID: 'JH',
        Name: 'Jharkhand'
    },
    {
        ID: 'KA',
        Name: 'Karnataka'
    },
    {
        ID: 'KL',
        Name: 'Kerala'
    },
    {
        ID: 'MP',
        Name: 'Madhya Pradesh'
    },
    {
        ID: 'MH',
        Name: 'Maharashtra'
    },
    {
        ID: 'MN',
        Name: 'Manipur'
    },
    {
        ID: 'ML',
        Name: 'Meghalaya'
    },
    {
        ID: 'MZ',
        Name: 'Mizoram'
    },
    {
        ID: 'NL',
        Name: 'Nagaland'
    },
    {
        ID: 'OD',
        Name: 'Odisha'
    },
    {
        ID: 'PB',
        Name: 'Punjab'
    },
    {
        ID: 'RJ',
        Name: 'Rajasthan'
    },
    {
        ID: 'SK',
        Name: 'Sikkim'
    },
    {
        ID: 'TN',
        Name: 'Tamil Nadu'
    },
    {
        ID: 'TS',
        Name: 'Telangana'
    },
    {
        ID: 'TR',
        Name: 'Tripura'
    },
    {
        ID: 'UK',
        Name: 'Uttarakhand'
    },
    {
        ID: 'UP',
        Name: 'Uttar Pradesh'
    },
    {
        ID: 'WB',
        Name: 'West Bengal'
    },
    {
        ID: 'AN',
        Name: 'Andaman and Nicobar Islands'
    },
    {
        ID: 'CH',
        Name: 'Chandigarh'
    },
    {
        ID: 'DN',
        Name: 'Dadra and Nagar Haveli'
    },
    {
        ID: 'DH',
        Name: 'Daman and Diu'
    },
    {
        ID: 'DL',
        Name: 'Delhi'
    },
    {
        ID: 'LD',
        Name: 'Lakshadweep'
    },
    {
        ID: 'PY',
        Name: 'Puducherry'
    },
    {
        ID: 'AD',
        Name: 'Andhra Pradesh'
    }
];

export const BIZ_TYPES = [
    {
        ID: 'individual',
        Name: 'Individual'
    },
    {
        ID: 'proprietership-or-huf',
        Name: 'Sole Proprietership/HUF'
    },
    {
        ID: 'partnership',
        Name: 'Partnership'
    },
    {
        ID: 'private-limited',
        Name: 'Private Limited'
    },
    {
        ID: 'trust-or-societies',
        Name: 'Trust/Societies'
    },
    {
        ID: 'llp',
        Name: 'LLP'
    },
    {
        ID: 'others',
        Name: 'Others'
    },
    {
        ID: 'unregistered-business',
        Name: 'Unregistred Business'
    },
    {
        ID: 'public-limited',
        Name: 'Public Limited'
    }
];

export const BIZ_CATEGORY = [
    {
        ID: 'agri_business',
        Name: 'Agri Business'
    },
    {
        ID: 'banking_or_financial_services',
        Name: 'Banking / Financial Services'
    },
    {
        ID: 'healthcare_firm',
        Name: 'Healthcare Firm'
    },
    {
        ID: 'food_and_beverages',
        Name: 'Food and Beverages'
    },
    {
        ID: 'textile',
        Name: 'Textile'
    },
    {
        ID: 'manufacturing',
        Name: 'Manufacturing'
    },
    {
        ID: 'telecom_and_telecom_equipment',
        Name: 'Telecom and Telecom Equipment'
    },
    {
        ID: 'realty_and_infrastructure',
        Name: 'Realty and Infrastructure'
    },
    {
        ID: 'broking_firm',
        Name: 'Broking Firm'
    },
    {
        ID: 'exporters_or_importers',
        Name: 'Exporters / Importers'
    },
    {
        ID: 'retail_merchants',
        Name: 'Retail Merchants'
    },
    {
        ID: 'logistics',
        Name: 'Logistics'
    },
    {
        ID: 'others',
        Name: 'Others'
    },
    {
        ID: 'arms_dealer_or_antique_dealer_or_art_dealer',
        Name: 'Arms Dealer/Antique Dealer/Art Dealer'
    },
    {
        ID: 'money_changer',
        Name: 'Money Changer'
    },
    {
        ID: 'gems_and_jewellery',
        Name: 'Gems and Jewellery'
    },
    {
        ID: 'real_estate_agents_or_construction',
        Name: 'Real Estate Agents/Construction'
    },
    {
        ID: 'hospitality',
        Name: 'Hospitality'
    },
    {
        ID: 'it_or_ites',
        Name: 'IT / ITeS'
    },
    {
        ID: 'brokers',
        Name: 'Brokers'
    },
    {
        ID: 'media_or_lifestyle_or_sports_or_advertmt_or_entertmt',
        Name: 'Media/Lifestyle/Sports/Advertmt/Entertmt'
    },
    {
        ID: 'associations',
        Name: 'Associations'
    },
    {
        ID: 'societies',
        Name: 'Societies'
    },
    {
        ID: 'clubs',
        Name: 'Clubs'
    },
    {
        ID: 'educational_institutions',
        Name: 'Educational Institutions'
    },
    {
        ID: 'defense',
        Name: 'Defense'
    },
    {
        ID: 'travel_and_tourism',
        Name: 'Travel and Tourism'
    },
    {
        ID: 'ca_firm',
        Name: 'CA Firm'
    },
    {
        ID: 'consultancy_firm',
        Name: 'Consultancy Firm'
    },
    {
        ID: 'multilevel_marketing_firm',
        Name: 'Multilevel Marketing Firm'
    },
    {
        ID: 'other_infrastructure',
        Name: 'Other Infrastructure'
    },
    {
        ID: 'cement',
        Name: 'Cement'
    },
    {
        ID: 'auto_and_auto_ancillaries',
        Name: 'Auto and Auto Ancillaries'
    },
    {
        ID: 'chemicals_dyes_and_paints',
        Name: 'Chemicals, Dyes and Paints'
    },
    {
        ID: 'pharmaceuticals',
        Name: 'Pharmaceuticals'
    },
    {
        ID: 'petroleum_oil_and_gas',
        Name: 'Petroleum, Oil and Gas'
    },
    {
        ID: 'power_and_power_distribution',
        Name: 'Power and Power Distribution'
    },
    {
        ID: 'shipping',
        Name: 'Shipping'
    },
    {
        ID: 'airlines',
        Name: 'Airlines'
    },
    {
        ID: 'consumer_durables',
        Name: 'Consumer Durables'
    },
    {
        ID: 'electronics_and_equipment',
        Name: 'Electronics and Equipment'
    },
    {
        ID: 'retailing',
        Name: 'Retailing'
    },
    {
        ID: 'real_estate',
        Name: 'Real Estate'
    },
    {
        ID: 'airports',
        Name: 'Airports'
    },
    {
        ID: 'shipyards',
        Name: 'Shipyards'
    },
    {
        ID: 'roads_and_ports',
        Name: 'Roads and Ports'
    },
    {
        ID: 'healthcare',
        Name: 'Healthcare'
    },
    {
        ID: 'epc',
        Name: 'EPC'
    },
    {
        ID: 'electrical_components',
        Name: 'Electrical Components'
    },
    {
        ID: 'sugar',
        Name: 'Sugar'
    },
    {
        ID: 'tea',
        Name: 'Tea'
    },
    {
        ID: 'paper_and_paper_products',
        Name: 'Paper and Paper Products'
    },
    {
        ID: 'rubber_and_rubber_products',
        Name: 'Rubber and Rubber Products'
    },
    {
        ID: 'iron_and_steel',
        Name: 'Iron and Steel'
    },
    {
        ID: 'other_metal_and_metal_products',
        Name: 'Other Metal and Metal Products'
    },
    {
        ID: 'coal',
        Name: 'Coal'
    },
    {
        ID: 'mining',
        Name: 'Mining'
    },
    {
        ID: 'petrochemicals',
        Name: 'Petrochemicals'
    },
    {
        ID: 'fertilizers',
        Name: 'Fertilizers'
    },
    {
        ID: 'financial_intermediaries',
        Name: 'Financial Intermediaries'
    },
    {
        ID: 'nbfc',
        Name: 'NBFC'
    },
    {
        ID: 'services',
        Name: 'Services'
    },
    {
        ID: 'computer_software',
        Name: 'Computer Software'
    },
    {
        ID: 'defence_establishments',
        Name: 'Defence Establishments'
    },
    {
        ID: 'housing_societies',
        Name: 'Housing Societies'
    },
    {
        ID: 'daily_wage',
        Name: 'Daily Wage'
    },
    {
        ID: 'financial_services',
        Name: 'Financial Services'
    },
    {
        ID: 'fashion_designing',
        Name: 'Fashion Designing'
    },
    {
        ID: 'ngo_or_npo',
        Name: 'NGO/NPO'
    },
    {
        ID: 'govertment_bodies',
        Name: 'Government Bodies'
    },
    {
        ID: 'bullion_dealers',
        Name: 'Bullion Dealers'
    },
    {
        ID: 'venture_capital_companies',
        Name: 'Venture Capital Companies'
    },
    {
        ID: 'embassy_or_consulates',
        Name: 'Embassy / Consulates'
    },
    {
        ID: 'firms_with_sleeping_partners',
        Name: 'Firms with Sleeping Partners'
    },
    {
        ID: 'tailoring_business',
        Name: 'Tailoring Business'
    },
    {
        ID: 'hardware',
        Name: 'Hardware'
    },
    {
        ID: 'electronic_or_electrical_accessories_shop',
        Name: 'Electronic / Electrical Accessories Shop'
    },
    {
        ID: 'provision_stores',
        Name: 'Provision Stores'
    },
    {
        ID: 'petty_shop',
        Name: 'Petty Shop'
    },
    {
        ID: 'eateries',
        Name: 'Eateries'
    },
    {
        ID: 'flower_vendor',
        Name: 'Flower Vendor'
    },
    {
        ID: 'fruit_vendor',
        Name: 'Fruit Vendor'
    },
    {
        ID: 'veg_vendor',
        Name: 'Veg Vendor'
    },
    {
        ID: 'proprietor',
        Name: 'Proprietor'
    },
    {
        ID: 'dtp_or_xerox',
        Name: 'DTP/Xerox'
    },
    {
        ID: 'trader',
        Name: 'Trader'
    },
    {
        ID: 'garage_owners',
        Name: 'Garage Owners'
    },
    {
        ID: 'hairdressers',
        Name: 'Hairdressers'
    },
    {
        ID: 'purohit_or_astrolger',
        Name: 'Purohit / Astrologer'
    },
    {
        ID: 'builder_or_contractor',
        Name: 'Builder / Contractor'
    },
    {
        ID: 'beauty_parlours',
        Name: 'Beauty Parlours'
    },
    {
        ID: 'sales_or_services',
        Name: 'Sales / Services'
    },
    {
        ID: 'agent',
        Name: 'Agent'
    },
    {
        ID: 'contractor',
        Name: 'Contractor'
    },
    {
        ID: 'shop_keeper',
        Name: 'Shop Keeper'
    },
    {
        ID: 'travels_transports',
        Name: 'Travels / Transports'
    },
    {
        ID: 'hotels_or_tea_shops_or_bakery_or_mess',
        Name: 'Hotels / Tea Shops / Bakery / Mess'
    },
    {
        ID: 'doctors',
        Name: 'Doctors'
    },
    {
        ID: 'photo_studio_or_album_making',
        Name: 'Photo Studio / Album Making'
    },
    {
        ID: 'electrician_or_plumber_or_carpenters',
        Name: 'Electrician / Plumber / Carpenters'
    }
];
